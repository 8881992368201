import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import SEO from 'components/seo';
import Layout from 'components/auxiliary/layout';
import { Container, Grid } from 'theme-ui';
import SectionHeader from 'components/section-header';
export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO title="Garaaz - Marketplace & SaaS for automobile spare parts" description="Omni channel marketplace and SaaS for distribution of spare parts in the automobile industry" keywords="garaaz, spare parts, automobile, svan autotech, automobile" />
			
    <section sx={{ variant: 'section.feature' }}>
      <Container>
        <SectionHeader
          slogan="What is Garaaz?"
          title="About us?"
        />
<p>
				Welcome to Svan Autotech, your number one source for all things Automobile parts.
 						We're dedicated to giving you the very best of spares and accessories, with a focus on quality & price.

						Founded in 2019 by Shaleen Agarwal, Svan Autotech has come a long way from its beginnings in Jaipur. 

We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us at shaleen@garaaz.com


Sincerely,

Shaleen				</p>
</Container>
</section>		
			</Layout>
    </StickyProvider>
  );
}
